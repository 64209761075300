import dotenv from "dotenv";
import { join } from "path";
// override using .env files on server and client
class Env {
  root = join.bind(this, __dirname, "../../");

  process = process.env;

  constructor() {
    if (typeof window === "undefined") {
      dotenv.config({
        path: this.root(process.env.NODE_ENV === "production" ? ".env.production" : ".env.development"),
      });
    }
  }

  getEnv(en) {
    if (typeof this.process[en] === "undefined") {
      throw new Error(`${en} equals ${typeof process.env[en]}!`);
    }
    return this.process[en];
  }
}

export const env = variable => new Env().getEnv(variable);
