// const { detect } = require("detect-browser");
// const browser = detect();
// const webpUnsupportedBrowsers = ["edge", "edge-chromium", "ie", "safari", "ios"];
// const webpUnsupportedOss = ["iOS", "Mac OS"];
/**
 * This method helps to convert existed image to more fast format.
 * It is helpful in cases when you need to pass only  image url.
 * If you need to use <img src={imgSrc} /> it is better tp use "Img" component instead.
 */
export default function (url) {
  // TODO temporary off cause does not work correctly
  // let correctSrc = url;
  // const isBrowserSupportWebp = !webpUnsupportedBrowsers.includes(browser.name);
  // const isOsSupportWebp = !webpUnsupportedOss.includes(browser.os);
  // if (process.env.NODE_ENV === "development") {
  //   return correctSrc;
  // }
  // if (url.indexOf("https") === -1 && url.indexOf("http") === -1) {

  //   if (isBrowserSupportWebp && isOsSupportWebp) {
  //     if (url.includes(".jpg")) {
  //       correctSrc = url.replace(".jpg", ".webp");
  //     }
  //     if (url.includes(".png")) {
  //       correctSrc = url.replace(".png", ".webp");
  //     }
  //   }
  // }
  // return correctSrc;

  return url;
}
