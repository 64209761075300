import { SET_EXPERTISE_SSR, OBTAIN_EXPERTISE_DATA, OBTAIN_EXPERTISE_ERROR } from "../actions";

const initialState = {
  isExpertiseSSR: false,
  content: {
    awards: [],
  },
  loading: false,
  error: [],
};

export default function servicesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_EXPERTISE_SSR:
      return {
        ...state,
        isExpertiseSSR: action.payload,
      };

    case OBTAIN_EXPERTISE_DATA:
      return {
        ...state,
        content: { ...state.content, ...action.payload },
        error: [],
      };

    case OBTAIN_EXPERTISE_ERROR:
      return {
        ...state,
        content: initialState.content,
        servicesError: action.payload,
      };

    default:
      return state;
  }
}
