const getConfig = () => {
  const config = {
    // root: 'body',
    // autoShow: true,
    // disablePageInteraction: true,
    // hideFromBots: true,
    // mode: 'opt-in',
    // revision: 0,
    disablePageInteraction: true,
    cookie: {
      name: "cc_cookie_demo2",
    },
    /**
     * Callback functions
     */
    onFirstConsent: ({ cookie }) => {
      console.log("onFirstConsent fired", cookie);
    },
    onConsent: ({ cookie }) => {
      console.log("onConsent fired!", cookie);
    },
    onChange: ({ changedCategories, changedServices }) => {
      console.log("onChange fired!", changedCategories, changedServices);
    },
    // https://cookieconsent.orestbida.com/reference/configuration-reference.html#guioptions
    guiOptions: {
      consentModal: {
        layout: "bar inline",
        position: "bottom left",
        equalWeightButtons: true,
        flipButtons: false,
      },
      preferencesModal: {
        layout: "box",
        equalWeightButtons: true,
        flipButtons: false,
      },
    },
    categories: {
      necessary: {
        enabled: true, // this category is enabled by default
        readOnly: true, // this category cannot be disabled
      },
      analytics: {
        autoClear: {
          cookies: [
            {
              name: /^_ga/, // regex: match all cookies starting with '_ga'
            },
            {
              name: "_gid", // string: exact cookie name
            },
          ],
        },
        // https://cookieconsent.orestbida.com/reference/configuration-reference.html#category-services
        services: {
          ga: {
            label: "Google Analytics",
            onAccept: () => {},
            onReject: () => {},
          },
          youtube: {
            label: "Youtube Embed",
            onAccept: () => {},
            onReject: () => {},
          },
        },
      },
      ads: {},
    },
    language: {
      default: "en",
      autoDetect: "document",
      translations: {
        en: {
          consentModal: {
            title: "We use cookies",
            description:
              "We and selected third parties use cookies or similar technologies for technical purposes and, with your consent, for functionality, experience, measurement and marketing (personalized ads) as specified in the privacy policy. <br/><br/>You can freely give, deny, or withdraw your consent at any time. Denying consent may make related features unavailable.<br/><br/> Use the “Accept all” button to consent. Use the “Reject all” button or close this notice to continue without accepting.",
            acceptAllBtn: "Accept all",
            acceptNecessaryBtn: "Reject all",
            footer: `
                        <a href="/privacy-policy">Privacy Policy</a>
                    `,
          },
          preferencesModal: {
            title: "Manage cookie preferences",
            acceptAllBtn: "Accept all",
            acceptNecessaryBtn: "Reject all",
            savePreferencesBtn: "Accept current selection",
            closeIconLabel: "Close modal",
            serviceCounterLabel: "Service|Services",
            sections: [
              {
                title: "Your Privacy Choices",
                description:
                  "We and selected third parties use cookies or similar technologies for technical purposes and, with your consent, for functionality, experience, measurement and marketing (personalized ads) as specified in the privacy policy.",
              },
              {
                title: "Strictly Necessary",
                description:
                  "These cookies are essential for the proper functioning of the website and cannot be disabled.",
                linkedCategory: "necessary",
              },
              {
                title: "Performance and Analytics",
                description:
                  "These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.",
                linkedCategory: "analytics",
                cookieTable: {
                  caption: "Cookie table",
                  headers: {
                    name: "Cookie",
                    domain: "Domain",
                    desc: "Description",
                  },
                  body: [
                    {
                      name: "_ga",
                      domain: window.location.hostname,
                      desc: "Description 1",
                    },
                    {
                      name: "_gid",
                      domain: window.location.hostname,
                      desc: "Description 2",
                    },
                  ],
                },
              },
              {
                title: "Targeting and Advertising",
                description:
                  "These cookies are used to make advertising messages more relevant to you and your interests. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.",
                linkedCategory: "ads",
              },
              {
                title: "More information",
                description:
                  'For any queries in relation to my policy on cookies and your choices, please <a href="#contact-page">contact us</a>',
              },
            ],
          },
        },
      },
    },
  };

  return config;
};

export default getConfig;
