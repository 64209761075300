import React from "react";
import UIcon from "../UIcon";

/* eslint-disable no-unused-vars */
export default function IconScraping({ children, className = "", ...restProps }) {
  return (
    <UIcon
      width="18"
      height="18"
      viewBox="0 0 18 18"
      path="M12.3327 12.3337C13.7135 12.3337 14.8327 13.4528 14.8327 14.8337C14.8327 16.2145 13.7135 17.3337 12.3327 17.3337C10.9518 17.3337 9.83268 16.2145 9.83268 14.8337C9.83268 13.4528 10.9518 12.3337 12.3327 12.3337ZM3.99935 9.00033C5.84102 9.00033 7.33268 10.492 7.33268 12.3337C7.33268 14.1753 5.84102 15.667 3.99935 15.667C2.15768 15.667 0.666016 14.1753 0.666016 12.3337C0.666016 10.492 2.15768 9.00033 3.99935 9.00033ZM11.0827 0.666992C13.6143 0.666992 15.666 2.71866 15.666 5.25033C15.666 7.78199 13.6143 9.83366 11.0827 9.83366C8.55102 9.83366 6.49935 7.78199 6.49935 5.25033C6.49935 2.71866 8.55102 0.666992 11.0827 0.666992Z"
      {...restProps}
    />
  );
}
