import React from "react";
import "./UMenuItem.scss";

export default function UMenuItem({ children, className = "", ...restProps }) {
  return (
    <span className={`u_menu_item ${className}`} {...restProps}>
      {children}
      <span className="u_menu_item-minus" />
    </span>
  );
}
