import React from "react";
import { Link } from "react-router-dom";

import Icon from "../SocialIcons";
import ULogo from "../../components/ULogo/ULogo";
import { footer } from "../../constants/links";
import { EXPERTISE_SUB_PAGE_LINK, PAGE_LINK, SERVICES_SUB_PAGE_LINK } from "../../constants/footerNavMenu";

import "./footer.scss";
import CookieConsent from "../CookieConsent";

const Footer = () => {
  const date = new Date();
  const fullYear = date.getFullYear();

  return (
    <footer className="footer" id="footer">
      <div className="footer-container u_container">
        <div className="footer-info">
          <Link to="/" className="logo">
            <ULogo secondary />
          </Link>
          <div className="description">
            <p>Ultimate software development</p>
            <span>Increasing your business success with technology</span>
          </div>
          <div className="social-list">
            {((footer && footer.mediaLinks && Array.isArray(footer.mediaLinks) && footer?.mediaLinks) || []).map(
              item => (
                <a target="_blank" className="social-list-item" key={item.name} href={item.url} rel="noreferrer">
                  <Icon icon={item.name} />
                </a>
              ),
            )}
          </div>
          <p className="privacy-block">
            © {fullYear} Powercode. All Rights Reserved. <Link to={footer.privacy}>Privacy Policy</Link>
          </p>
        </div>
        <div className="footer-link-container">
          <div className="link-list">
            {SERVICES_SUB_PAGE_LINK.map(({ title, path }) => (
              <Link to={`/services/${path}`} key={`key-${title}`}>
                {title}
              </Link>
            ))}
          </div>
          <div className="link-list">
            {EXPERTISE_SUB_PAGE_LINK.map(({ title, path }) => (
              <Link to={`/expertise/${path}`} key={`key-${title}`}>
                {title}
              </Link>
            ))}
          </div>
          <div className="link-list">
            {PAGE_LINK.map(({ title, path }) =>
              title === "Careers" ? (
                <a href={path} target="_blank" key={`key-${title}`} rel="noreferrer">
                  {title}
                </a>
              ) : (
                <Link to={path} key={`key-${title}`}>
                  {title}
                </Link>
              ),
            )}
          </div>
        </div>
        <div className="social-list mobile">
          {(footer?.mediaLinks || []).map(item => (
            <a target="_blank" className="social-list-item" key={item.name} href={item.url} rel="noreferrer">
              <Icon icon={item.name} />
            </a>
          ))}
        </div>
        <p className="privacy-block mobile">
          © {fullYear} Powercode. All Rights Reserved. <Link to={footer.privacy}>Privacy Policy</Link>
        </p>
      </div>
      <CookieConsent />
    </footer>
  );
};

export default Footer;
