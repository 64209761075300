export const footer = {
  pagesLinks: [
    // { title: "home", link: "/" },
    // { title: "careers", link: "https://careers.powercode.pro/" },
    { title: "company", link: "/our-team" },
    { title: "services", link: "/services/mobile" },
    { title: "case studies", link: "/case-studies" },
    { title: "Insights", link: "/blog" },
  ],
  mediaLinks: [
    { name: "fb", url: "https://www.facebook.com/powercode.co.uk" },
    { name: "ln", url: "https://www.linkedin.com/company/powercodecouk/" },
    { name: "tw", url: "https://twitter.com/powercode_co_uk" },
    { name: "in", url: "https://www.instagram.com/powercode.co.uk/" },
    { name: "be", url: "https://www.behance.net/powercode" },
    { name: "dr", url: "https://dribbble.com/Powercode" },
  ],
  mail: "contact@powercode.co.uk",
  privacy: "/privacy-policy",
};

export const header = {
  menu: [
    {
      title: "company",
      url: "/our-team",
      subLinks: [
        { title: "about us", url: "/our-team" },
        { title: "locations", url: "/locations" },
        { title: "team", url: "/our-team" },
        { title: "outstaff", url: "/our-team" },
      ],
    },
    {
      title: "services",
      url: "/services",
      subLinks: [
        { title: "product branding", url: "/services/product-branding" },
        { title: "IT Consulting & Analysis", url: "/services/it-consulting" },
        { title: "Dedicated team model", url: "/services/dedicated-team-model" },
        {
          title: "Web & Mobile development",
          url: "/services/web-mobile-development",
        },
        { title: "Quality Assurance", url: "/services/quality-assurance" },
        { title: "Devops", url: "/services/devops" },
      ],
    },
    {
      title: "case studies",
      url: "/case-studies",
      subLinks: [
        { title: "custom software", url: "/case-studies" },
        { title: "mobile apps", url: "/mob-portfolio" },
      ],
    },
    // {
    //   title: "careers",
    //   url: "https://careers.powercode.pro/",
    //   subLinks: [
    //     { title: "Open vacancies", url: "https://careers.powercode.pro/" },
    //     { title: "our offices", url: "https://careers.powercode.pro/map" },
    //   ],
    // },
    { title: "Insights", url: "/blog" },
  ],
};

export const services = {
  title: "Services",
  paragraph:
    "Every company needs fast and secure development so that the software works for a brand name and end goal. We offer a wide range of services that includes not only engineering, but also digital protection, thorough testing, valuable consulting, and many more. If you want to enhance your software development, delivery, deployment, or security, you may choose DevOps. To create an impeccable brand, use our Product Branding service. A Quality Assurance team will help you check if your app has any defects that impact your performance. In case you need to get to know your business opportunities and chances with web development, we will eagerly conduct IT consulting & analysis for you. As a separate service, we also offer UI & UX design, brand book creation, digital strategy development, dedicated team, and security consulting.",
  capabilities: [
    { title: "Product Branding", url: "/services/product-branding" },
    { title: "IT Consulting & Analysis", url: "/services/it-consulting" },
    { title: "Dedicated Team Model", url: "/services/dedicated-team-model" },
    { title: "Web & Mobile Development", url: "/services/web-mobile-development" },
    { title: "Quality Assurance", url: "/services/quality-assurance" },
    { title: "Devops", url: "/services/devops" },
  ],
};

export const about = {
  title: "About us",
  paragraph:
    "Building IT solutions is our passion, and we are going to boost your project performance by creating an app that will meet all of your business needs. Having more than 100 experts in our regular staff, we are happy to implement your ideas and surpass your expectations with superiority of solutions. You can either get a full-cycle software development service or a certain element of it. If you want to enhance your software development, delivery, deployment, or security, you may choose DevOps. To create an impeccable brand, use our Product Branding service. A Quality Assurance team will help you check if your app has any defects that impact your performance. In case you need to get to know your business opportunities and chances with web development, we will eagerly conduct IT consulting & analysis for you. As a separate service, we also offer UI & UX design, brand book creation, digital strategy development, dedicated team, and security consulting.",
  capabilities: [
    { title: "Product Branding", url: "/services/product_branding" },
    { title: "IT Consulting & Analysis", url: "/services/it_consulting" },
    { title: "Dedicated Team Model", url: "/services/dedicated_team_model" },
    { title: "Web & Mobile Development", url: "/services/web_mobile_development" },
    { title: "Quality Assurance", url: "/services/quality_assurance" },
    { title: "Devops", url: "/services/devops" },
  ],
};
