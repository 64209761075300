import React from "react";

import "./index.scss";

const FBChatPhone = ({ deviceWidth }) => (
  <div className="fb-phone-container">
    {deviceWidth > 768 ? (
      <div className="desktop">
        <a href="tel:+442045772044">
          <img src="/assets/images/fb-phone-icon.svg" alt="phone-icon" />
          <span>+44 204 577 20 44</span>
        </a>
      </div>
    ) : (
      <a href="tel:+442045772044">
        <img src="/assets/images/fb-mob-phone-icon.svg" alt="phone-icon" />
      </a>
    )}
  </div>
);

export default FBChatPhone;
