import {
  SET_BLOG_SSR,
  SET_BLOG_ITEM_SSR,
  SET_CURRENT_BLOG,
  OBTAIN_BLOG_DATA,
  OBTAIN_BLOG_ERROR,
  OBTAIN_BLOG_ITEM_DATA,
  OBTAIN_BLOG_ITEM_ERROR,
  OBTAIN_BLOG_SIMILAR_DATA,
  OBTAIN_BLOG_SIMILAR_ERROR,
  OBTAIN_BLOG_CATEGORIES,
} from "../actions";

export const DEFAULT_CATEGORY = {
  label: "All categories",
  value: 0,
};
const initialState = {
  isBlogSSR: false,
  isBlogItemSSR: false,
  blogList: [],
  categoriesList: [DEFAULT_CATEGORY],
  blogListTotal: 0,
  blogListError: [],
  blogItem: {},
  blogItemError: [],
  blogSimilar: [],
  blogSimilarError: [],
  currentBlog: null,
};

export default function blogReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_BLOG:
      return {
        ...state,
        currentBlog: action.payload,
      };
    case SET_BLOG_SSR:
      return {
        ...state,
        isBlogSSR: action.payload,
      };
    case SET_BLOG_ITEM_SSR:
      return {
        ...state,
        isBlogItemSSR: action.payload,
      };
    case OBTAIN_BLOG_DATA:
      return {
        ...state,
        blogList: action.payload.data,
        blogListTotal: action.payload.total,
        blogListError: [],
      };
    case OBTAIN_BLOG_CATEGORIES:
      return {
        ...state,
        categoriesList: [...state.categoriesList, ...action.payload],
        blogListError: [],
      };
    case OBTAIN_BLOG_ERROR:
      return {
        ...state,
        blogList: [],
        blogListError: action.payload,
      };
    case OBTAIN_BLOG_ITEM_DATA:
      return {
        ...state,
        blogItem: action.payload,
        blogItemError: [],
      };
    case OBTAIN_BLOG_ITEM_ERROR:
      return {
        ...state,
        blogItem: [],
        blogItemError: action.payload,
      };
    case OBTAIN_BLOG_SIMILAR_DATA:
      return {
        ...state,
        blogSimilar: action.payload,
        blogSimilarError: [],
      };
    case OBTAIN_BLOG_SIMILAR_ERROR:
      return {
        ...state,
        blogSimilar: [],
        blogSimilarError: action.payload,
      };
    default:
      return state;
  }
}
