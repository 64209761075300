export default {
  "service-mobile": () => import(/* webpackChunkName: "mobile" */ "./servicePageDataMobile"),
  "service-web": () => import(/* webpackChunkName: "web" */ "./servicePageDataWeb"),
  "service-design": () => import(/* webpackChunkName: "design" */ "./servicePageDataDesign"),
  "service-ba": () => import(/* webpackChunkName: "ba" */ "./servicePageDataBA"),
  "service-legacy": () => import(/* webpackChunkName: "legacy" */ "./servicePageDataLegacy"),
  "service-qa": () => import(/* webpackChunkName: "qa" */ "./servicePageDataQA"),
  "service-consulting": () => import(/* webpackChunkName: "consulting" */ "./servicePageDataConsulting"),
  "service-mvp": () => import(/* webpackChunkName: "mvp" */ "./servicePageDataMVP"),
  "service-third-web": () => import(/* webpackChunkName: "third-web" */ "./servicePageDataThirdWeb"),
  "service-web-scraping": () => import(/* webpackChunkName: "web-scraping" */ "./servicePageDataWebScraping"),
};
