export const SERVICES_SUB_PAGE_LINK = [
  {
    title: "Mobile development",
    path: "mobile",
  },
  {
    title: "Web development",
    path: "web",
  },
  {
    title: "Design",
    path: "design",
  },
  {
    title: "Business Analytics",
    path: "ba",
  },
  {
    title: "Legacy Code Modernization",
    path: "legacy",
  },
  {
    title: "Quality Assurance",
    path: "qa",
  },
  {
    title: "Ecommerce Consulting",
    path: "consulting",
  },
  {
    title: "MVP",
    path: "mvp",
  },
];

export const EXPERTISE_SUB_PAGE_LINK = [
  {
    title: "Ecommerce",
    path: "ecommerce",
  },
  {
    title: "Foodtech",
    path: "foodtech",
  },
  {
    title: "Healthcare",
    path: "healthcare",
  },
  {
    title: "Enterprise solutions",
    path: "enterprise",
  },
  {
    title: "Education",
    path: "education",
  },
  {
    title: "Entertainment",
    path: "entertainment",
  },
  {
    title: "Real estate",
    path: "realestate",
  },
  {
    title: "Hospitality",
    path: "hospitality",
  },
];

export const PAGE_LINK = [
  {
    title: "Company",
    path: "/our-team",
  },
  {
    title: "Careers",
    path: "https://careers.vsholding.tech/",
  },
  {
    title: "Case Studies",
    path: "/case-studies",
  },
  {
    title: "Insights",
    path: "/blog",
  },
];
