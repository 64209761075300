import { API } from "../Api";

export const OBTAIN_HOME_DATA = "OBTAIN_HOME_DATA";
export const OBTAIN_HOME_ERROR = "OBTAIN_HOME_ERROR";
export const SET_HOME_SSR = "SET_HOME_SSR";

export function setHomeSSR(isSSR) {
  return {
    type: SET_HOME_SSR,
    payload: isSSR,
  };
}

function obtainHomeData(data) {
  return {
    type: OBTAIN_HOME_DATA,
    payload: data,
  };
}

function obtainHomeError(data) {
  return {
    type: OBTAIN_HOME_ERROR,
    payload: data,
  };
}

export const getHomeContent = () => async dispatch => {
  try {
    const contentRes = API.get("/pages/1858");
    const industriesRes = API.get("/expertise");
    const reviewsRes = API.get("/reviews");
    const partnersRes = API.get("/partners");
    const awardsRes = API.get("/awards");

    const [content, industries, reviews, partners, awards] = await Promise.all([
      contentRes,
      industriesRes,
      reviewsRes,
      partnersRes,
      awardsRes,
    ]);
    return dispatch(
      obtainHomeData({
        ...(Boolean(content.data.acf) && content.data.acf),
        reviews: reviews.data,
        partners: partners.data,
        industries: ((industries && industries.data && Array.isArray(industries.data) && industries.data) || []).map(
          i => ({
            id: i?.id,
            icon: i?.acf?.icon,
            label: i?.acf?.subtitle,
          }),
        ),
        awards: awards?.data,
      }),
    );
  } catch (err) {
    dispatch(obtainHomeError(["Error"]));
  }
};
