import { createStore, compose, applyMiddleware } from "redux";
import ReduxThunk from "redux-thunk";
import { reducers } from "../reducers/index";

const createStoreWithMiddleware = compose(applyMiddleware(ReduxThunk))(createStore);

function devTools() {
  if (typeof window !== "undefined") {
    return window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
  }
  return undefined;
}

export default function configureStore(initialState = {}) {
  return process.env.NODE_ENV === "production"
    ? createStoreWithMiddleware(reducers, initialState)
    : createStoreWithMiddleware(reducers, initialState, devTools());
}
