import React, { useEffect, useState } from "react";

export default function ULogo({ secondary, mobile }) {
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  const logoImg =
    !mounted || mobile
      ? "/assets/images/logoMobile.svg"
      : secondary
      ? "/assets/images/logoSecondary.svg"
      : "/assets/images/logoPrimary.svg";

  return <img src={logoImg} alt="Logo" />;
}
