import "vanilla-cookieconsent/dist/cookieconsent.css";

import { useEffect } from "react";
import * as CookieConsent from "vanilla-cookieconsent";

import getConfig from "./config";
import addEventListeners from "./listeners";

const CookieConsentComponent = () => {
  useEffect(() => {
    addEventListeners();
    CookieConsent.run(getConfig());
  }, []);

  // eslint-disable-next-line react/react-in-jsx-scope
  return <div />;
};

export default CookieConsentComponent;
