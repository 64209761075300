import React from "react";
import UIcon from "../UIcon";

/* eslint-disable no-unused-vars */
export default function IconEllipsis({ children, className = "", ...restProps }) {
  return (
    <UIcon
      width="22"
      height="4"
      viewBox="0 0 22 4"
      path="M2 4C3.10457 4 4 3.10457 4 2C4 0.89543 3.10457 0 2 0C0.89543 0 0 0.89543 0 2C0 3.10457 0.89543 4 2 4ZM11 4C12.1046 4 13 3.10457 13 2C13 0.89543 12.1046 0 11 0C9.89543 0 9 0.89543 9 2C9 3.10457 9.89543 4 11 4ZM22 2C22 3.10457 21.1046 4 20 4C18.8954 4 18 3.10457 18 2C18 0.89543 18.8954 0 20 0C21.1046 0 22 0.89543 22 2Z"
      {...restProps}
    />
  );
}
