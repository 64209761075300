import React from "react";
import "./UMenuCard.scss";

/* eslint-disable react/display-name */
export default function UMenuCard({ children, className = "", ...restProps }) {
  return (
    <div className={`u_menu_card ${className}`} {...restProps}>
      {children}
    </div>
  );
}

UMenuCard.Icon = function ({ children }) {
  return <div className="u_menu_card-icon">{children}</div>;
};

UMenuCard.Label = function ({ children }) {
  return <div className="u_menu_card-label">{children}</div>;
};

UMenuCard.Description = function ({ children }) {
  return <div className="u_menu_card-description">{children}</div>;
};
