import loadable from "@loadable/component";
import Loader from "./components/Loader";

export const HomePage = loadable(() => import(/* webpackChunkName: "homePage" */ "./pages/HomePage"), {
  fallback: Loader,
  ssr: true,
});

export const Expertise = loadable(() => import(/* webpackChunkName: "expertise" */ "./pages/Expertise"), {
  fallback: Loader,
  ssr: true,
});

export const PrivacyPolicy = loadable(() => import(/* webpackChunkName: "privacy" */ "./pages/PrivacyPolicy"), {
  fallback: Loader,
  ssr: true,
});

export const OurTeam = loadable(() => import(/* webpackChunkName: "ourTeam" */ "./pages/OurTeam"), {
  fallback: Loader,
  ssr: true,
});

export const Locations = loadable(() => import(/* webpackChunkName: "locations" */ "./pages/Locations"), {
  fallback: Loader,
  ssr: true,
});

export const Portfolio = loadable(() => import(/* webpackChunkName: "portfolio" */ "./pages/Portfolio"), {
  fallback: Loader,
});

export const PortfolioPages = loadable(
  () => import(/* webpackChunkName: "portfolioPages" */ "./pages/PortfolioPages"),
  {
    fallback: Loader,
    ssr: true,
  },
);

export const Blog = loadable(() => import(/* webpackChunkName: "blog" */ "./pages/Blog"), {
  fallback: Loader,
  ssr: true,
});

export const BlogPages = loadable(() => import(/* webpackChunkName: "blogPages" */ "./pages/BlogPages"), {
  fallback: Loader,
  ssr: true,
});

export const MessageSent = loadable(() => import(/* webpackChunkName: "messageSent" */ "./pages/MessageSent"), {
  fallback: Loader,
  ssr: true,
});

export const EmptyPage = loadable(() => import(/* webpackChunkName: "emptyPage" */ "./pages/EmptyPage"), {
  fallback: Loader,
  ssr: true,
});

export const Contact = loadable(() => import(/* webpackChunkName: "contact" */ "./pages/Contact"), {
  fallback: Loader,
  ssr: true,
});

export const ContactUsNew = loadable(() => import(/* webpackChunkName: "contact-us-new" */ "./pages/ContactUsNew"), {
  fallback: Loader,
  ssr: true,
});
export const CaseStudies = loadable(() => import(/* webpackChunkName: "caseStudies" */ "./pages/CaseStudies"), {
  fallback: Loader,
  ssr: true,
});

export const UCaseStudiesSubPage = loadable(
  () =>
    import(
      /* webpackChunkName: "uCaseStudiesSubPage" */ "./pages/UCaseStudies/UCaseStudiesSubPage/UCaseStudiesSubPage.js"
    ),
  {
    fallback: Loader,
    ssr: true,
  },
);

export const ServicePage = loadable(
  () => import(/* webpackChunkName: "ServicePage" */ "./pages/ServicePage/ServicePage.js"),
  {
    fallback: Loader,
    ssr: true,
  },
);
