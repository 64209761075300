import React from "react";
import crossPlatformImage from "../../helpers/crossPlatformImage";
import fallbackImg from "../../assets/images/1x1.png";
import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";

function Img({ src, alt, className, lazy = true, ...restProps }) {
  let correctSrc = crossPlatformImage(src);

  return (
    <img
      {...restProps}
      {...(lazy
        ? {
            "data-src": correctSrc,
            src: fallbackImg,
          }
        : {
            src: correctSrc,
          })}
      alt={alt}
      data-src={correctSrc}
      className={`${className} 
    ${lazy ? "lazyload" : ""}`}
    />
  );
}

export default Img;
