import React from "react";
import UIcon from "../UIcon";

/* eslint-disable no-unused-vars */
export default function IconBarChart({ children, className = "", ...restProps }) {
  return (
    <UIcon
      width="18"
      height="18"
      viewBox="0 0 18 18"
      path="M2 0H16C17.1 0 18 0.9 18 2V16C18 17.1 17.1 18 16 18H2C0.9 18 0 17.1 0 16V2C0 0.9 0.9 0 2 0ZM4 13C4 13.55 4.45 14 5 14C5.55 14 6 13.55 6 13V8C6 7.45 5.55 7 5 7C4.45 7 4 7.45 4 8V13ZM9 14C8.45 14 8 13.55 8 13V5C8 4.45 8.45 4 9 4C9.55 4 10 4.45 10 5V13C10 13.55 9.55 14 9 14ZM12 13C12 13.55 12.45 14 13 14C13.55 14 14 13.55 14 13V11C14 10.45 13.55 10 13 10C12.45 10 12 10.45 12 11V13Z"
      {...restProps}
    />
  );
}
