import React from "react";
import "./save-ukraine.scss";
import "./save-ukraine-mob.scss";
import UContainer from "../UContainer/UContainer";

const SaveUkraine = ({ className }) => {
  return (
    <div className={`save-ukraine ${className || ""}`}>
      <UContainer className="save-ukraine__content">
        <h6 className="save-ukraine__title">Keep Calm and Save Ukraine</h6>
        <div className="save-ukraine__flag" />
      </UContainer>
    </div>
  );
};

export default SaveUkraine;
