import { SET_SERVICES_SSR, OBTAIN_SERVICES_DATA, OBTAIN_SERVICES_ERROR, SET_EXPERTISE_LOADING } from "../actions";

const initialState = {
  content: { services: null, service_team: null, awards: [] },
  isServicesSSR: false,
  servicesError: [],
};

export default function servicesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SERVICES_SSR:
      return {
        ...state,
        isServicesSSR: action.payload,
      };

    case OBTAIN_SERVICES_DATA:
      return {
        ...state,
        content: { ...state.content, ...action.payload },
        servicesError: [],
      };

    case OBTAIN_SERVICES_ERROR:
      return {
        ...state,
        services: null,
        servicesError: action.payload,
      };
    case SET_EXPERTISE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
}
