import React from "react";
import "./UMenuButton.scss";

export default function UMenuButton({ className = "", openChildren, closeChildren, isOpen, ...restProps }) {
  const openClass = isOpen ? "u_menu_button--opened" : "";
  return (
    <div style={{ position: "relative" }}>
      <button className={`u_menu_button ${className} ${openClass}`} {...restProps}>
        <span className="u_menu_button-open_child">{openChildren}</span>
        <span className="u_menu_button-close_child">{closeChildren}</span>
      </button>
      <span className="u_menu_button-circles" />
    </div>
  );
}
