import React from "react";
import UIcon from "../UIcon";

/* eslint-disable no-unused-vars */
export default function IconShieldTic({ children, className = "", ...restProps }) {
  return (
    <UIcon
      width="20"
      height="24"
      viewBox="0 0 20 24"
      path="M1.29818 3.6832L8.93455 0.290477C9.49091 0.0395676 10.1455 0.0395676 10.7018 0.290477L18.3382 3.6832C19.1236 4.03229 19.6364 4.81775 19.6364 5.67957V10.8068C19.6364 16.8614 15.4473 22.5231 9.81818 23.8977C4.18909 22.5231 0 16.8614 0 10.8068V5.67957C0 4.81775 0.512727 4.03229 1.29818 3.6832ZM4.03636 13.7522L6.86182 16.5777C7.28727 17.0031 7.98545 17.0031 8.4 16.5777L15.5891 9.38866C16.0145 8.9632 16.0145 8.27593 15.5891 7.85048C15.1636 7.42502 14.4764 7.42502 14.0509 7.85048L7.63636 14.2651L5.57455 12.2141C5.14909 11.7886 4.46182 11.7886 4.03636 12.2141C3.83209 12.4179 3.71728 12.6946 3.71728 12.9831C3.71728 13.2718 3.83209 13.5485 4.03636 13.7522Z"
      {...restProps}
    />
  );
}
