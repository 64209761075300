import React from "react";
import UIcon from "../UIcon";

/* eslint-disable no-unused-vars */
export default function IconSupervisor({ children, className = "", ...restProps }) {
  return (
    <UIcon
      width="24"
      height="16"
      viewBox="0 0 24 16"
      path="M11.988 3.64286C11.988 5.65857 10.392 7.28571 8.39999 7.28571C6.40799 7.28571 4.79999 5.65857 4.79999 3.64286C4.79999 1.62714 6.40799 0 8.39999 0C10.392 0 11.988 1.62714 11.988 3.64286ZM20.388 5.46428C20.388 7.14 19.056 8.5 17.4 8.5C15.744 8.5 14.4 7.14 14.4 5.46428C14.4 3.78857 15.744 2.42857 17.4 2.42857C19.056 2.42857 20.388 3.78857 20.388 5.46428ZM17.4 9.92857C15.204 9.92857 10.8 11.0457 10.8 13.2679V14.7857C10.8 15.4536 11.34 16 12 16H22.8C23.46 16 24 15.4536 24 14.7857V13.2679C24 11.0457 19.596 9.92857 17.4 9.92857ZM0 12.9643C0 10.135 5.604 8.71429 8.4 8.71429C9.192 8.71429 10.2 8.83572 11.244 9.05429C8.796 10.4264 8.4 12.2357 8.4 13.2679V16H1.2C0.54 16 0 15.4536 0 14.7857V12.9643Z"
      {...restProps}
    />
  );
}
