import { OBTAIN_HOME_DATA, OBTAIN_HOME_ERROR, SET_HOME_SSR } from "../actions";

const initialState = {
  isHomeSSR: false,
  content: {
    estimation_project_group: {},
    reasons_proud_group: {},
    industries: [],
    reviews: [],
    partners: [],
    awards: [],
  },
  error: [],
};

export default function homeReducer(state = initialState, action) {
  switch (action.type) {
    case OBTAIN_HOME_DATA:
      return {
        ...state,
        content: action.payload,
      };
    case SET_HOME_SSR:
      return {
        ...state,
        isHomeSSR: action.payload,
      };
    case OBTAIN_HOME_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
}
