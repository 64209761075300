import { API } from "../Api";
import servicePagesData from "../pages/ServicePage/data/servicePagesData";

export const SET_SERVICES_SSR = "SET_SERVICES_SSR";
export const OBTAIN_SERVICES_DATA = "OBTAIN_SERVICES_DATA";
export const OBTAIN_SERVICES_ERROR = "OBTAIN_SERVICES_ERROR";

export function setServicesSSR(isSSR) {
  return {
    type: SET_SERVICES_SSR,
    payload: isSSR,
  };
}

export function obtainServicesData(data) {
  return {
    type: OBTAIN_SERVICES_DATA,
    payload: data,
  };
}

export function obtainServicesError(error) {
  return {
    type: OBTAIN_SERVICES_ERROR,
    payload: error,
  };
}

export const getServices = slug => async dispatch => {
  try {
    const dataLoader = servicePagesData["service-" + slug];
    const servicesRes = dataLoader();
    const serviceTeamRes = API.get(`services?slug=${slug}`);
    const awardsRes = API.get("/awards");
    const [servicesData, serviceTeam, awards] = await Promise.all([servicesRes, serviceTeamRes, awardsRes]);

    return dispatch(
      obtainServicesData({
        services: servicesData?.default,
        service_team: serviceTeam?.data?.[0]?.acf,
        awards: awards?.data,
      }),
    );
  } catch (e) {
    return dispatch(obtainServicesError(["error!"]));
  }
};
