import React from "react";
import "./UContainer.scss";

export default function UContainer({ children, fullWidth, small, main = true, className, ...restProps }) {
  let additionalClasses = "";
  if (fullWidth) additionalClasses += " u_container--full_width";
  if (main) additionalClasses += " u_container--main";
  if (small) additionalClasses += " u_container--small";
  return (
    <section className={`u_container${additionalClasses} ${className || ""}`} {...restProps}>
      {children}
    </section>
  );
}
