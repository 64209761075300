import React, { Component, Fragment } from "react";
import ModalDialog from "../ModalDialog";
import RequestForm from "../RequestForm";

import "./header.scss";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import UHeader from "../../containers/UHeader/UHeader";

export default class Header extends Component {
  state = {
    modalOpen: false,
  };

  toggleModal = () => {
    this.state.menuOpen && disableBodyScroll(document.body);
    !this.state.menuOpen && enableBodyScroll(document.body);
    this.setState({ modalOpen: !this.state.modalOpen });
  };

  render() {
    return (
      <Fragment>
        <ModalDialog isShow={this.state.modalOpen} onClose={this.toggleModal}>
          <div className="formWrap">
            <RequestForm closeRequestModal={this.toggleModal} />
          </div>
        </ModalDialog>
        <div>
          <UHeader />
        </div>
      </Fragment>
    );
  }
}
