import { OBTAIN_COMPANY_DATA, OBTAIN_COMPANY_ERROR, SET_COMPANY_SSR } from "../actions/companyAction";

const initialState = {
  isCompanySSR: false,
  content: {
    who_we_are_group: {},
    we_are_powercode_group: {},
    our_team: [],
    team_group: {},
    social_mission_group: {},
    office_views_group: {},
    team_reviews: {},
  },
  error: [],
};

export default function companyReducer(state = initialState, action) {
  switch (action.type) {
    case OBTAIN_COMPANY_DATA:
      return {
        ...state,
        content: action.payload,
      };
    case SET_COMPANY_SSR:
      return {
        ...state,
        isCompanySSR: action.payload,
      };
    case OBTAIN_COMPANY_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
}
