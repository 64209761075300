import React from "react";
import UIcon from "../UIcon";

/* eslint-disable no-unused-vars */
export default function IconThirdWeb({ children, className = "", ...restProps }) {
  return (
    <UIcon
      width="22"
      height="22"
      viewBox="0 0 30 30"
      path="M25.1037 13.1243L26.6062 14.0255C26.6989 14.081 26.7756 14.1596 26.8289 14.2536C26.8822 14.3476 26.9102 14.4538 26.9102 14.5618C26.9102 14.6698 26.8822 14.776 26.8289 14.87C26.7756 14.964 26.6989 15.0426 26.6062 15.098L14.9999 22.0618L3.39369 15.098C3.301 15.0426 3.22427 14.964 3.171 14.87C3.11772 14.776 3.08972 14.6698 3.08972 14.5618C3.08972 14.4538 3.11772 14.3476 3.171 14.2536C3.22427 14.1596 3.301 14.081 3.39369 14.0255L4.89619 13.1243L14.9999 19.1868L25.1037 13.1243ZM25.1037 18.9993L26.6062 19.9005C26.6989 19.956 26.7756 20.0346 26.8289 20.1286C26.8822 20.2226 26.9102 20.3288 26.9102 20.4368C26.9102 20.5448 26.8822 20.651 26.8289 20.745C26.7756 20.839 26.6989 20.9176 26.6062 20.973L15.6437 27.5505C15.4493 27.6673 15.2267 27.7291 14.9999 27.7291C14.7731 27.7291 14.5506 27.6673 14.3562 27.5505L3.39369 20.973C3.301 20.9176 3.22427 20.839 3.171 20.745C3.11772 20.651 3.08972 20.5448 3.08972 20.4368C3.08972 20.3288 3.11772 20.2226 3.171 20.1286C3.22427 20.0346 3.301 19.956 3.39369 19.9005L4.89619 18.9993L14.9999 25.0618L25.1037 18.9993ZM15.6424 1.63554L26.6062 8.21304C26.6989 8.26853 26.7756 8.3471 26.8289 8.44109C26.8822 8.53507 26.9102 8.64126 26.9102 8.74929C26.9102 8.85733 26.8822 8.96351 26.8289 9.0575C26.7756 9.15148 26.6989 9.23005 26.6062 9.28554L14.9999 16.2493L3.39369 9.28554C3.301 9.23005 3.22427 9.15148 3.171 9.0575C3.11772 8.96351 3.08972 8.85733 3.08972 8.74929C3.08972 8.64126 3.11772 8.53507 3.171 8.44109C3.22427 8.3471 3.301 8.26853 3.39369 8.21304L14.3562 1.63554C14.5506 1.51874 14.7731 1.45703 14.9999 1.45703C15.2267 1.45703 15.4493 1.51874 15.6437 1.63554H15.6424Z"
      {...restProps}
    />
  );
}
