import { API } from "../Api";
export const SET_EXPERTISE_SSR = "SET_EXPERTISE_SSR";
export const OBTAIN_EXPERTISE_DATA = "OBTAIN_EXPERTISE_DATA";
export const OBTAIN_EXPERTISE_ERROR = "OBTAIN_EXPERTISE_ERROR";
export const SET_EXPERTISE_LOADING = "SET_EXPERTISE_LOADING";

export function setExpertiseSSR(isSSR) {
  return {
    type: SET_EXPERTISE_SSR,
    payload: isSSR,
  };
}

export function obtainExpertiseData(data) {
  return {
    type: OBTAIN_EXPERTISE_DATA,
    payload: data,
  };
}

export function obtainExpertiseError(error) {
  return {
    type: OBTAIN_EXPERTISE_ERROR,
    payload: error,
  };
}
export function setLoading(loading) {
  return {
    type: SET_EXPERTISE_LOADING,
    payload: loading,
  };
}

export const getExpertiseContent = async dispatch => {
  try {
    setLoading(true);
    const awards = await API.get("/awards");
    dispatch(obtainExpertiseData({ awards: awards.data }));
    return setLoading(false);
  } catch (e) {
    setLoading(false);
    return dispatch(obtainExpertiseError(["error!"]));
  }
};
