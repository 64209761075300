import { API } from "../Api";
import { Agent } from "https";

export const SET_CASE_SSR = "SET_CASE_SSR";
export const SET_CURRENT_CASE = "SET_CURRENT_CASE";
export const SET_CASE_ITEM_SSR = "SET_CASE_ITEM_SSR";
export const OBTAIN_CASE_DATA = "OBTAIN_CASE_DATA";
export const OBTAIN_CASE_CATEGORIES = "OBTAIN_CASE_CATEGORIES";
export const OBTAIN_CASE_ERROR = "OBTAIN_CASE_ERROR";
export const OBTAIN_CASE_ITEM_DATA = "OBTAIN_CASE_ITEM_DATA";
export const OBTAIN_CASE_ITEM_ERROR = "OBTAIN_CASE_ITEM_ERROR";
export const OBTAIN_CASE_SIMILAR_DATA = "OBTAIN_CASE_SIMILAR_DATA";
export const OBTAIN_CASE_SIMILAR_ERROR = "OBTAIN_CASE_SIMILAR_ERROR";

const agent = new Agent({
  rejectUnauthorized: false,
});

export function setCaseSSR(isSSR) {
  return {
    type: SET_CASE_SSR,
    payload: isSSR,
  };
}

export function setCaseItemSSR(isSSR) {
  return {
    type: SET_CASE_ITEM_SSR,
    payload: isSSR,
  };
}

export function setCurrentCase(payload) {
  return {
    type: SET_CURRENT_CASE,
    payload,
  };
}

export function obtainCaseData(data, total) {
  return {
    type: OBTAIN_CASE_DATA,
    payload: { data, total },
  };
}

function obtainCaseCategories(data) {
  return {
    type: OBTAIN_CASE_CATEGORIES,
    payload: data,
  };
}

function obtainCaseError(error) {
  return {
    type: OBTAIN_CASE_ERROR,
    payload: error,
  };
}

function obtainCaseItemData(payload) {
  return {
    type: OBTAIN_CASE_ITEM_DATA,
    payload,
  };
}

function obtainCaseItemError(error) {
  return {
    type: OBTAIN_CASE_ITEM_ERROR,
    payload: error,
  };
}

function obtainCaseSimilarData(data) {
  return {
    type: OBTAIN_CASE_SIMILAR_DATA,
    payload: data,
  };
}

function obtainCaseSimilarError(error) {
  return {
    type: OBTAIN_CASE_SIMILAR_ERROR,
    payload: error,
  };
}

const getCases = async params => {
  const req = await API.get("/cases?_embed=1", { params });
  return {
    data: req.data,
    total: +req.headers["x-wp-total"],
  };
};

const getCurrentCase = (data, currentId, dispatch) => {
  // const store = configureStore();
  const currentCase = data.find(el => el.slug === currentId);
  dispatch(setCurrentCase(currentCase || null));
};

export const getCasesList =
  (params, currentCaseId = 0) =>
  async dispatch => {
    try {
      const { data, total } = await getCases(params);
      dispatch(obtainCaseData(data, total));

      if (currentCaseId) {
        getCurrentCase(data, currentCaseId, dispatch);
      }

      return Promise.resolve();
    } catch (e) {
      dispatch(obtainCaseError(["Error!"]));
    }
  };

export const getCasesItem = slug => async dispatch => {
  const req = await API.get(`/cases?slug=${slug}&_embed=1`, { httpsAgent: agent });

  if (req.status === 200) {
    if (req.data.length > 0) {
      try {
        const acf = req?.data?.[0]?.acf ?? {};
        acf.categories = req?.data?.[0]?.cases_categories;
        acf.expertise = req?.data?.[0]?.expertise;
        req.data[0].acf = acf;
        dispatch(getCasesSimilar(req?.data?.[0]?.id, req?.data?.[0]?.categories, 0));
      } catch (error) {
        console.error("cases", error);
      }
    }

    return dispatch(obtainCaseItemData(req?.data));
  }

  return dispatch(obtainCaseItemError(["error!"]));
};

export const getCaseCategories = () => async dispatch => {
  try {
    const res = await API.get("/cases_categories");

    const categories = ((res && res.data && Array.isArray(res.data) && res.data) || []).map(el => {
      return {
        label: el.name,
        value: el.id,
        slug: el.slug,
      };
    });

    return dispatch(obtainCaseCategories(categories));
  } catch (e) {
    dispatch(obtainCaseError(["Error!"]));
  }
};

export const resetCaseItem = () => async dispatch => {
  return dispatch(obtainCaseItemData({}));
};

export const getCasesSimilar = (articleId, keywords) => async dispatch => {
  try {
    const req = await API.get(`/cases?categories=0,${keywords.toString()}&_embed=1`);
    const data = req.data.filter(item => item.id !== articleId);
    dispatch(obtainCaseSimilarData(data));
  } catch (e) {
    dispatch(obtainCaseSimilarError(["error!", e]));
  }
};
