import React from "react";
import UIcon from "../UIcon";

/* eslint-disable no-unused-vars */
export default function IconMobileGear({ children, className = "", ...restProps }) {
  return (
    <UIcon
      width="18"
      height="22"
      viewBox="0 0 18 22"
      path="M3.90744 2V4C3.90744 4.55 4.35744 5 4.90744 5C5.45744 5 5.90744 4.55 5.90744 4V3H15.9074V19H5.90744V18C5.90744 17.45 5.45744 17 4.90744 17C4.35744 17 3.90744 17.45 3.90744 18V20C3.90744 21.1 4.80744 22 5.90744 22H15.9074C17.0074 22 17.9074 21.1 17.9074 20V2C17.9074 0.9 17.0074 0 15.9074 0H5.90744C4.80744 0 3.90744 0.9 3.90744 2ZM6.40744 14.5C6.69744 14.38 6.95744 14.21 7.2074 14.02L7.1874 14.05L8.1974 14.44C8.4274 14.53 8.6874 14.44 8.8074 14.22L9.6474 12.76C9.7674 12.55 9.7174 12.27 9.5274 12.12L8.6774 11.44L8.6574 11.47C8.6624 11.43 8.6681 11.39 8.6737 11.35C8.6906 11.23 8.7074 11.11 8.7074 10.99C8.7074 10.87 8.6906 10.75 8.6737 10.63C8.6681 10.59 8.6624 10.55 8.6574 10.51L8.6774 10.54L9.5274 9.86C9.7174 9.71 9.7674 9.43 9.6474 9.22L8.8074 7.76C8.6874 7.55 8.4274 7.45 8.1974 7.54L7.1874 7.93L7.2074 7.96C6.95744 7.79 6.69744 7.62 6.40744 7.5L6.23744 6.42C6.20744 6.18 5.99744 6 5.74744 6H4.06744C3.81744 6 3.60744 6.18 3.57744 6.42L3.40744 7.5C3.11744 7.62 2.85744 7.79 2.60744 7.98L2.62744 7.95L1.60744 7.56C1.37744 7.47 1.11744 7.56 0.997443 7.78L0.157443 9.24C0.0374434 9.45 0.0874434 9.73 0.277443 9.88L1.12744 10.56L1.14744 10.53C1.14301 10.5632 1.13809 10.597 1.13311 10.6311C1.11562 10.7509 1.09744 10.8755 1.09744 11C1.09744 11.12 1.11432 11.24 1.13119 11.36C1.13682 11.4 1.14244 11.44 1.14744 11.48L1.12744 11.45L0.277443 12.13C0.0874434 12.28 0.0374434 12.56 0.157443 12.77L0.997443 14.23C1.11744 14.44 1.37744 14.54 1.60744 14.45L2.61744 14.06L2.60744 14.02C2.85744 14.21 3.11744 14.38 3.40744 14.5L3.57744 15.57C3.60744 15.82 3.81744 16 4.06744 16H5.74744C5.99744 16 6.20744 15.82 6.23744 15.58L6.40744 14.5ZM4.90744 9C3.80744 9 2.90744 9.9 2.90744 11C2.90744 12.1 3.80744 13 4.90744 13C6.00744 13 6.90744 12.1 6.90744 11C6.90744 9.9 6.00744 9 4.90744 9Z"
      {...restProps}
    />
  );
}
