import React from "react";
import "./loader.scss";

export default function Loading(props) {
  if (props.isLoading) {
    return (
      <div className="loader-wrapper">
        <div className="preloader" />
      </div>
    );
  } else if (props.error && !props.isLoading) {
    return <p>Error!</p>;
  } else {
    return null;
  }
}
