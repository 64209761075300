import { API } from "../Api";
import { Agent } from "https";

export const SET_BLOG_SSR = "SET_BLOG_SSR";
export const SET_BLOG_ITEM_SSR = "SET_BLOG_ITEM_SSR";
export const SET_CURRENT_BLOG = "SET_CURRENT_BLOG";
export const OBTAIN_BLOG_DATA = "OBTAIN_BLOG_DATA";
export const OBTAIN_BLOG_CATEGORIES = "OBTAIN_BLOG_CATEGORIES";
export const OBTAIN_BLOG_ERROR = "OBTAIN_BLOG_ERROR";
export const OBTAIN_BLOG_ITEM_DATA = "OBTAIN_BLOG_ITEM_DATA";
export const OBTAIN_BLOG_ITEM_ERROR = "OBTAIN_BLOG_ITEM_ERROR";
export const OBTAIN_BLOG_SIMILAR_DATA = "OBTAIN_BLOG_SIMILAR_DATA";
export const OBTAIN_BLOG_SIMILAR_ERROR = "OBTAIN_BLOG_SIMILAR_ERROR";

const agent = new Agent({
  rejectUnauthorized: false,
});

export function setBlogSSR(isSSR) {
  return {
    type: SET_BLOG_SSR,
    payload: isSSR,
  };
}

export function setBlogItemSSR(isSSR) {
  return {
    type: SET_BLOG_ITEM_SSR,
    payload: isSSR,
  };
}

export function setCurrentBlog(payload) {
  return {
    type: SET_CURRENT_BLOG,
    payload,
  };
}

function obtainBlogData(data, total) {
  return {
    type: OBTAIN_BLOG_DATA,
    payload: { data, total },
  };
}
function obtainBlogCategories(data) {
  return {
    type: OBTAIN_BLOG_CATEGORIES,
    payload: data,
  };
}

function obtainBlogError(error) {
  return {
    type: OBTAIN_BLOG_ERROR,
    payload: error,
  };
}

function obtainBlogItemData(payload) {
  return {
    type: OBTAIN_BLOG_ITEM_DATA,
    payload,
  };
}

function obtainBlogItemError(error) {
  return {
    type: OBTAIN_BLOG_ITEM_ERROR,
    payload: error,
  };
}

function obtainBlogSimilarData(data) {
  return {
    type: OBTAIN_BLOG_SIMILAR_DATA,
    payload: data,
  };
}

function obtainBlogSimilarError(error) {
  return {
    type: OBTAIN_BLOG_SIMILAR_ERROR,
    payload: error,
  };
}

const currentParams = (categories, page) => {
  return categories ? { page, categories } : { page };
};

const getArticles = async (page = 1, categories) => {
  const req = await API.get("/articles?_embed=1", { params: currentParams(categories, page) });
  return {
    data: req.data,
    total: +req.headers["x-wp-total"],
  };
};

const getCurrentBlog = (data, currentId, dispatch) => {
  // const store = configureStore();
  const currentBlog = data.find(el => el.slug === currentId);
  dispatch(setCurrentBlog(currentBlog || null));
};

export const getBlogList =
  (page = 0, categoryId = 0, currentBlogId = 0) =>
  async dispatch => {
    try {
      const { data, total } = await getArticles(page, categoryId);
      dispatch(obtainBlogData(data, total));

      if (currentBlogId) {
        getCurrentBlog(data, currentBlogId, dispatch);
      }

      return Promise.resolve();
    } catch (e) {
      dispatch(obtainBlogError(["Error!"]));
    }
  };
export const getBlogCategories = () => async dispatch => {
  try {
    const res = await API.get("/categories?exclude=1");
    const categories = [
      ...((res && res.data && Array.isArray(res.data) && res.data) || [])
        .filter(obj => !!obj.count)
        .map(el => ({ label: el.name, value: el.id, slug: el.slug })),
    ];
    return dispatch(obtainBlogCategories(categories));
  } catch (e) {
    dispatch(obtainBlogError(["Error!"]));
  }
};

export const getBlogItem = slug => async dispatch => {
  dispatch(obtainBlogItemData({}));
  const req = await API.get(`/articles?slug=${slug}&_embed=1`, { httpsAgent: agent });

  if (req.status === 200) {
    if (req.data.length > 0) {
      dispatch(getBlogSimilar(req.data[0].id, req.data[0].categories, 0));
    }
    return dispatch(obtainBlogItemData(req.data));
  }

  return dispatch(obtainBlogItemError(["error!"]));
};

export const getBlogSimilar = (articleId, keywords) => async dispatch => {
  try {
    const req = await API.get(`/articles?categories=0,${keywords.toString()}&_embed=1`);
    const data = req.data.filter(item => item.id !== articleId);
    dispatch(obtainBlogSimilarData(data));
  } catch (e) {
    dispatch(obtainBlogSimilarError(["error!", e]));
  }
};
