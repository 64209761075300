import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import "./UHeader.scss";
import ULogo from "../../components/ULogo/ULogo";
import UButton from "../../components/UButton/UButton";
import UContainer from "../../components/UContainer/UContainer";
import UMenuCard from "./components/UMenuCard/UMenuCard";
import UMenuItem from "./components/UMenuItem/UMenuItem";
import UMenuButton from "./components/UMenuButton/UMenuButton";
import IconEllipsis from "../../components/UIcon/icons/IconEllipsis";
import IconCross from "../../components/UIcon/icons/IconCross";
import { AppContext } from "../../AppHooks";
import IconSupervisor from "../../components/UIcon/icons/IconSupervisor";
import IconPallet from "../../components/UIcon/icons/IconPallet";
import IconBarChart from "../../components/UIcon/icons/IconBarChart";
import IconShieldTic from "../../components/UIcon/icons/IconShieldTic";
import IconServer from "../../components/UIcon/icons/IconServer";
import IconTarget from "../../components/UIcon/icons/IconTarget";
import IconMobileGear from "../../components/UIcon/icons/IconMobileGear";
import IconWebSite from "../../components/UIcon/icons/IconWebSite";
import SaveUkraine from "../../components/SaveUkraine/SaveUkraine";
import IconThirdWeb from "../../components/UIcon/icons/IconThirdWeb";
import IconScraping from "../../components/UIcon/icons/IconScraping";

export default function UHeader() {
  const location = useLocation();
  const history = useHistory();
  const [isTop, setIsTop] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isActiveLink, setIsActiveLink] = useState("");

  useEffect(() => {
    window.addEventListener("scroll", () => {
      const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollPosition > 0 && isTop) {
        setIsTop(false);
      } else {
        setIsTop(true);
      }
    });
    return () => {
      window.removeEventListener("scroll", () => {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
        if (scrollPosition > 0 && isTop) {
          setIsTop(false);
        } else {
          setIsTop(true);
        }
      });
    };
  }, []);

  function handleMenuToggle() {
    document.body.classList.toggle("overflow");
    setIsOpen(!isOpen);
  }

  function handleCloseMenu() {
    setIsOpen(false);
    document.body.classList.remove("overflow");
  }

  const handleClick = ({ target }) => {
    if (target.classList.contains("close-menu-trigger")) return;
    handleCloseMenu();
    setIsActiveLink("");
  };

  useEffect(() => {
    let elements = document.getElementsByClassName("close-menu-trigger");
    let hover1 = elements[0];
    let hover2 = elements[4];
    hover1.addEventListener("mouseover", () => {
      if (hover1.classList.contains("hide")) {
        hover1.classList.toggle("hide");
      }
    });
    hover2.addEventListener("mouseover", () => {
      if (hover2.classList.contains("hide")) {
        hover2.classList.toggle("hide");
      }
    });
  });

  const appContext = useContext(AppContext);
  const displayType = appContext.displayTypeNew.get();
  const isDesktopFormat = displayType === "xl" || displayType === "full";

  if (isOpen && isDesktopFormat) {
    handleCloseMenu();
  }

  let conditionClasses = "";
  conditionClasses += isTop ? "" : " u_header--scrolled";
  conditionClasses += isOpen ? " u_header--opened" : "";

  let navigationConditionClasses = "";
  navigationConditionClasses += isOpen ? " u_navigation--opened" : "";

  const handleScrollToForm = () => {
    if (location.pathname !== "/contact") {
      history.push("/contact");
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }

    handleCloseMenu();
  };

  const handleExpertiseActive = () => {
    isActiveLink === "expertise" ? setIsActiveLink("") : setIsActiveLink("expertise");
    let elements = document.getElementsByClassName("close-menu-trigger");
    let hover = elements[0];
    hover.classList.toggle("hide");
  };

  const handleServicesActive = () => {
    isActiveLink === "services" ? setIsActiveLink("") : setIsActiveLink("services");
    let elements = document.getElementsByClassName("close-menu-trigger");
    let hover = elements[4];
    hover.classList.toggle("hide");
  };

  return (
    <header className={`u_header ${conditionClasses}`}>
      <SaveUkraine className="u_header__save-ukraine" />
      <UContainer className="u_header-container-wrap">
        <div className="u_header-container">
          <div className="u_header-left">
            <Link to="/">
              <div onClick={handleCloseMenu} className={isDesktopFormat ? "logo-primary" : "logo-mobile"}>
                <ULogo isHeader mobile={!isDesktopFormat} />
              </div>
            </Link>
            <div className="u_header-mobile_toolbar">
              <UButton onClick={handleScrollToForm} className={"mobHeaderBtn"}>
                Get in touch
              </UButton>
              <UMenuButton
                openChildren={<IconEllipsis />}
                closeChildren={<IconCross />}
                onClick={handleMenuToggle}
                isOpen={isOpen}
              />
            </div>
          </div>
          <div className="u_header-right">
            <nav className={`u_navigation ${navigationConditionClasses}`} onClick={handleClick}>
              <ul>
                <li className="unstyled">
                  <Link to="/our-team">
                    <UMenuItem>Company</UMenuItem>
                  </Link>
                </li>
                <li
                  className={`close-menu-trigger ${isActiveLink === "expertise" ? "u_navigation--active" : ""}`}
                  onClick={handleExpertiseActive}
                >
                  <ul>
                    <li>
                      <Link to="/expertise/ecommerce">
                        <UMenuCard>
                          <UMenuCard.Label>
                            <UMenuCard.Icon>
                              <IconMobileGear />
                            </UMenuCard.Icon>
                            Ecommerce
                          </UMenuCard.Label>
                          <UMenuCard.Description>
                            Online stores, marketplace platforms, SaaS, and other solutions for your business
                          </UMenuCard.Description>
                        </UMenuCard>
                      </Link>
                    </li>
                    <li>
                      <Link to="/expertise/foodtech">
                        <UMenuCard>
                          <UMenuCard.Label>
                            <UMenuCard.Icon>
                              <img src="/assets/images/expertise/foodtech.svg" alt="Icon" />
                            </UMenuCard.Icon>
                            Foodtech
                          </UMenuCard.Label>
                          <UMenuCard.Description>
                            CRM systems, delivery platforms, online grocery stores, and a lot more
                          </UMenuCard.Description>
                        </UMenuCard>
                      </Link>
                    </li>
                    <li>
                      <Link to="/expertise/healthcare">
                        <UMenuCard>
                          <UMenuCard.Label>
                            <UMenuCard.Icon>
                              <img src="/assets/images/expertise/healthcare.svg" alt="Icon" />
                            </UMenuCard.Icon>
                            Healthcare
                          </UMenuCard.Label>
                          <UMenuCard.Description>
                            Doctor booking apps, telehealth platforms, online pharmacy, and other solutions
                          </UMenuCard.Description>
                        </UMenuCard>
                      </Link>
                    </li>
                    <li>
                      <Link to="/expertise/education">
                        <UMenuCard>
                          <UMenuCard.Label>
                            <UMenuCard.Icon>
                              <img src="/assets/images/expertise/education.svg" alt="Icon" />
                            </UMenuCard.Icon>
                            Education
                          </UMenuCard.Label>
                          <UMenuCard.Description>
                            E-learning platforms, childcare apps, CRM, streaming services, and others
                          </UMenuCard.Description>
                        </UMenuCard>
                      </Link>
                    </li>
                    <li>
                      <Link to="/expertise/entertainment">
                        <UMenuCard>
                          <UMenuCard.Label>
                            <UMenuCard.Icon>
                              <img src="/assets/images/expertise/entertainment.svg" alt="Icon" />
                            </UMenuCard.Icon>
                            Entertainment
                          </UMenuCard.Label>
                          <UMenuCard.Description>
                            Messaging apps, streaming and online TV, social media, and more solutions for you
                          </UMenuCard.Description>
                        </UMenuCard>
                      </Link>
                    </li>
                    <li>
                      <Link to="/expertise/realestate">
                        <UMenuCard>
                          <UMenuCard.Label>
                            <UMenuCard.Icon>
                              <img src="/assets/images/expertise/real_estate.svg" alt="Icon" />
                            </UMenuCard.Icon>
                            Real estate
                          </UMenuCard.Label>
                          <UMenuCard.Description>
                            Booking apps, marketplace, corporate platforms, payment system integration, etc.
                          </UMenuCard.Description>
                        </UMenuCard>
                      </Link>
                    </li>
                    <li>
                      <Link to="/expertise/hospitality">
                        <UMenuCard>
                          <UMenuCard.Label>
                            <UMenuCard.Icon>
                              <img src="/assets/images/expertise/ecommerce.svg" alt="Icon" />
                            </UMenuCard.Icon>
                            Hospitality
                          </UMenuCard.Label>
                          <UMenuCard.Description>
                            Web and mobile apps, social media platforms, CRM systems, and other software
                          </UMenuCard.Description>
                        </UMenuCard>
                      </Link>
                    </li>
                    <li>
                      <Link to="/expertise/enterprise">
                        <UMenuCard>
                          <UMenuCard.Label>
                            <UMenuCard.Icon>
                              <img src="/assets/images/expertise/enterprise_solutions.svg" alt="Icon" />
                            </UMenuCard.Icon>
                            Enterprise solutions
                          </UMenuCard.Label>
                          <UMenuCard.Description>
                            Cybersecurity, ERP, corporate networks, and more solutions for your business
                          </UMenuCard.Description>
                        </UMenuCard>
                      </Link>
                    </li>
                    {/*TODO wait for desired pages*/}
                    {/*<li>
                      <Link to="/expertise/enterprise">
                        <UMenuCard>
                          <UMenuCard.Label>
                            <UMenuCard.Icon>
                              <img src={devops} alt="Icon" />
                            </UMenuCard.Icon>
                            Devops
                          </UMenuCard.Label>
                          <UMenuCard.Description>
                            Messaging apps, streaming and online TV, social media, and more solutions for you
                          </UMenuCard.Description>
                        </UMenuCard>
                      </Link>
                    </li>
                    <li>
                      <Link to="/expertise/enterprise">
                        <UMenuCard>
                          <UMenuCard.Label>
                            <UMenuCard.Icon>
                              <img src={seo} alt="Icon" />
                            </UMenuCard.Icon>
                            SEO
                          </UMenuCard.Label>
                          <UMenuCard.Description>
                            Booking apps, marketplace, corporate platforms, payment system integration, etc.
                          </UMenuCard.Description>
                        </UMenuCard>
                      </Link>
                    </li>*/}
                  </ul>
                  {/*<Link to={"/expertise/ecommerce"}>*/}
                  <div className="sub-menu close-menu-trigger">
                    <UMenuItem className="close-menu-trigger">Expertise</UMenuItem>
                    <img
                      src={
                        isActiveLink === "expertise"
                          ? "/assets/images/homepage/minus.svg"
                          : "/assets/images/homepage/plus.svg"
                      }
                      alt="Expertise"
                      className="close-menu-trigger"
                    />
                  </div>
                  {/*</Link>*/}
                </li>
                <li
                  className={`close-menu-trigger ${isActiveLink === "services" ? "u_navigation--active" : ""}`}
                  onClick={handleServicesActive}
                >
                  <ul>
                    <li>
                      <Link to="/services/mobile">
                        <UMenuCard>
                          <UMenuCard.Label>
                            <UMenuCard.Icon>
                              <IconMobileGear />
                            </UMenuCard.Icon>
                            Mobile Development
                          </UMenuCard.Label>
                          <UMenuCard.Description>
                            Our iOS, Android, and cross-platform development services for your business
                          </UMenuCard.Description>
                        </UMenuCard>
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/web">
                        <UMenuCard>
                          <UMenuCard.Label>
                            <UMenuCard.Icon>
                              <IconWebSite />
                            </UMenuCard.Icon>
                            Web Development
                          </UMenuCard.Label>
                          <UMenuCard.Description>
                            Web software we develop for startups, companies, and enterprises
                          </UMenuCard.Description>
                        </UMenuCard>
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/design">
                        <UMenuCard>
                          <UMenuCard.Label>
                            <UMenuCard.Icon>
                              <IconPallet />
                            </UMenuCard.Icon>
                            Design
                          </UMenuCard.Label>
                          <UMenuCard.Description>
                            Design solutions that&apos;ll make your product custom and outstanding
                          </UMenuCard.Description>
                        </UMenuCard>
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/ba">
                        <UMenuCard>
                          <UMenuCard.Label>
                            <UMenuCard.Icon>
                              <IconTarget />
                            </UMenuCard.Icon>
                            Business Analytics
                          </UMenuCard.Label>
                          <UMenuCard.Description>
                            Empowering your business idea and our software solutions with analytics
                          </UMenuCard.Description>
                        </UMenuCard>
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/legacy">
                        <UMenuCard>
                          <UMenuCard.Label>
                            <UMenuCard.Icon>
                              <IconServer />
                            </UMenuCard.Icon>
                            Legacy Code Modernization
                          </UMenuCard.Label>
                          <UMenuCard.Description>
                            How we can enhance the quality of your digital business product
                          </UMenuCard.Description>
                        </UMenuCard>
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/qa">
                        <UMenuCard>
                          <UMenuCard.Label>
                            <UMenuCard.Icon>
                              <IconShieldTic />
                            </UMenuCard.Icon>
                            QA
                          </UMenuCard.Label>
                          <UMenuCard.Description>
                            Why Quality Assurance is vital for the success of your digital product
                          </UMenuCard.Description>
                        </UMenuCard>
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/consulting">
                        <UMenuCard>
                          <UMenuCard.Label>
                            <UMenuCard.Icon>
                              <IconBarChart />
                            </UMenuCard.Icon>
                            Ecommerce Consulting
                          </UMenuCard.Label>
                          <UMenuCard.Description>
                            Create a successful ecommerce product from scratch with our assistance
                          </UMenuCard.Description>
                        </UMenuCard>
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/mvp">
                        <UMenuCard>
                          <UMenuCard.Label>
                            <UMenuCard.Icon>
                              <IconSupervisor />
                            </UMenuCard.Icon>
                            MVP
                          </UMenuCard.Label>
                          <UMenuCard.Description>
                            Get a Minimum Viable Product version to test your business idea
                          </UMenuCard.Description>
                        </UMenuCard>
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/third-web">
                        <UMenuCard>
                          <UMenuCard.Label>
                            <UMenuCard.Icon>
                              <IconThirdWeb />
                            </UMenuCard.Icon>
                            Web 3.0
                          </UMenuCard.Label>
                          <UMenuCard.Description>
                            Messaging apps, streaming and online TV, social media, and more solutions for you
                          </UMenuCard.Description>
                        </UMenuCard>
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/web-scraping">
                        <UMenuCard>
                          <UMenuCard.Label>
                            <UMenuCard.Icon>
                              <IconScraping />
                            </UMenuCard.Icon>
                            Web Scraping
                          </UMenuCard.Label>
                          <UMenuCard.Description>
                            Booking apps, marketplace, corporate platforms, payment system integration, etc.
                          </UMenuCard.Description>
                        </UMenuCard>
                      </Link>
                    </li>
                  </ul>
                  <div className="sub-menu close-menu-trigger">
                    <UMenuItem className={"close-menu-trigger"}>Services</UMenuItem>
                    <img
                      src={
                        isActiveLink === "services"
                          ? "/assets/images/homepage/minus.svg"
                          : "/assets/images/homepage/plus.svg"
                      }
                      alt="Services"
                      className="close-menu-trigger"
                    />
                  </div>
                </li>
                <li className="unstyled">
                  <Link to="/case-studies">
                    <UMenuItem>Case Studies</UMenuItem>
                  </Link>
                </li>
                <li className="unstyled">
                  <Link to="/blog">
                    <UMenuItem>Insights</UMenuItem>
                  </Link>
                </li>
              </ul>
            </nav>
            <UButton onClick={handleScrollToForm} className="headerBtn get-in-touch">
              Get in touch
            </UButton>
            <a href="https://careers.vsholding.tech/" target="_blank" rel="noopener noreferrer" className="work-link">
              <span>Work</span>
              <img src="/assets/images/homepage/arrow-header.svg" alt="arrow" />
            </a>
          </div>
        </div>
      </UContainer>
    </header>
  );
}
