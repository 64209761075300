import React from "react";
import "./UIcon.scss";

export default function UIcon({
  path,
  height = "40",
  width = "40",
  viewBox = "0 0 40 40",
  className = "",
  char = true,
  fillRule,
  fill,
  ...restProps
}) {
  let variantClasses = "";
  if (char) variantClasses += " u_icon--char";

  return (
    <i className={`u_icon${variantClasses} ${className}`} {...restProps}>
      <svg
        height={height}
        width={width}
        fillRule={fillRule || "evenodd"}
        clipRule="evenodd"
        viewBox={viewBox}
        fill={fill}
      >
        <path d={path} />
      </svg>
    </i>
  );
}
