import React from "react";
import UIcon from "../UIcon";

/* eslint-disable no-unused-vars */
export default function IconServer({ children, className = "", ...restProps }) {
  return (
    <UIcon
      width="22"
      height="18"
      viewBox="0 0 22 18"
      path="M0 2.2C0 3.41 0.99 4.4 2.2 4.4H19.8C21.01 4.4 22 3.41 22 2.2C22 0.99 21.01 0 19.8 0H2.2C0.99 0 0 0.99 0 2.2ZM19.8 17.6H2.2C0.99 17.6 0 16.61 0 15.4C0 14.19 0.99 13.2 2.2 13.2H19.8C21.01 13.2 22 14.19 22 15.4C22 16.61 21.01 17.6 19.8 17.6ZM4.4 14.3H2.2V16.5H4.4V14.3ZM2.2 3.3H4.4V1.1H2.2V3.3ZM2.2 11H19.8C21.01 11 22 10.01 22 8.8C22 7.59 21.01 6.6 19.8 6.6H2.2C0.99 6.6 0 7.59 0 8.8C0 10.01 0.99 11 2.2 11ZM4.4 7.7H2.2V9.9H4.4V7.7Z"
      {...restProps}
    />
  );
}
