import React, { Component, createRef } from "react";
import InputMask from "react-input-mask";
import { withRouter } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import TextareaAutosize from "react-textarea-autosize";
import { Field, Form, Formik } from "formik";
import mixed from "yup/lib/mixed";
import object from "yup/lib/object";
import string from "yup/lib/string";

import { API } from "../../Api";

import "./index.scss";

const FILE_SIZE = 10 * 1000 * 1000;
const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/png",
  "image/jpeg",
  "application/pdf",
  "application/msword",
  "application/vnd.ms-excel",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
];

class RequestForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sending: false,
    };
    this.fileInput = createRef();
  }

  handleDeleteFile = setFieldValue => () => {
    this.fileInput.current.value = "";
    setFieldValue("file", "");
  };

  handleRenderFileInput = (errors, values, setFieldValue) => {
    return (
      <div className={`input-container attach ${!!errors.file ? "error" : ""}`}>
        <div className="attach-info">
          <label className={`attach-file ${values.file && "hide"}`}>
            <input
              name="file"
              type="file"
              onChange={event => {
                setFieldValue("file", event.currentTarget.files[0]);
              }}
              ref={this.fileInput}
            />
            <img src={"/assets/images/homepage/insert_drive1.svg"} alt="file" />
            <span>Attach file</span>
          </label>
          {values.file && (
            <div className="attached-file">
              <img
                onClick={this.handleDeleteFile(setFieldValue)}
                className="clear"
                src={"/assets/images/homepage/clear-attached.svg"}
                alt="clear"
              />
              <img src={"/assets/images/homepage/attached.svg"} alt="attached" />
              <p>{values.file.name}</p>
            </div>
          )}
        </div>
        <div className="inputHelper">{errors.file}</div>
      </div>
    );
  };

  RequestQuoteSchema = () => {
    return object().shape({
      name: string()
        .required("Please enter your name")
        .min(3, "Name must be at least 3 characters")
        .max(30, "Name must be less than 31 characters")
        .test("match", "Enter the correct name", value => {
          const nameTemplate = /^[^\s]+(\s.*)?$/;
          return nameTemplate.test(value);
        }),
      company: string().test("match", "Enter the correct company", value => {
        const nameTemplate = /^[^\s]+(\s.*)?$/;
        return nameTemplate.test(value);
      }),
      phone: string()
        .required("Please enter your phone")
        .min(6, "Phone must be at least 5 characters")
        .max(15, "Phone must be less than 15 characters")
        .test("match", "Enter the correct phone", value => {
          const phoneTemplate = /^\+\d{5,14}/i;
          return phoneTemplate.test(value);
        }),
      email: string()
        .email("Email in invalid")
        .required("Please enter your email")
        .min(5, "Email must be at least 5 characters")
        .max(320, "Email must be less than 321 characters")
        .test("match", "Enter the correct email", value => {
          const emailTemplate = /.+@.+\..+/i;
          return emailTemplate.test(value);
        }),
      description: string().required("Please enter description").min(10, "Description must be at least 10 characters"),
      file: mixed()
        .test("fileSize", "File Size is too large", value => (value ? value.size <= FILE_SIZE : true))
        .test("fileType", "Unsupported File Format", value =>
          value || null ? SUPPORTED_FORMATS.includes(value.type) : true,
        ),
    });
  };

  render() {
    return (
      <div className="requestaQuote" id={this.props.id ? this.props.id : "requestaQuoteId"}>
        <h2 className="title">Let’s talk!</h2>
        <h5 className={"subtitle"}>Fill out our form below or send us an email</h5>
        <div className="requestFormRow">
          <Formik
            initialValues={{
              name: "",
              company: "",
              phone: "",
              email: "",
              description: "",
              file: "",
            }}
            validationSchema={this.RequestQuoteSchema()}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={values => {
              requestForm(
                {
                  sourceName: this.props.sourceName,
                  sourceURL: window && window.location ? window.location.href : "",
                  ...values,
                },
                () => {
                  if (this.props.closeRequestModal) {
                    this.props.closeRequestModal();
                  }
                },
              );
              this.props.history.push("/message_sent");
            }}
            render={({ errors, values, setFieldError, setFieldValue }) => {
              return (
                <Form className="wrapRequestForm">
                  <div className="input-row">
                    <Field
                      name="name"
                      render={({ field }) => (
                        <div className={`input-container ${!!errors.name ? "error" : ""}`}>
                          <p> Your Name* </p>
                          <input
                            className="input"
                            type="text"
                            pattern="[a-zA-Zа-яА-Я'-'\s]*"
                            placeholder="Name"
                            {...field}
                            onFocus={() => {
                              setFieldError("name", "");
                            }}
                          />
                          <div className="inputHelper">{errors.name}</div>
                        </div>
                      )}
                    />
                    <Field
                      name="company"
                      render={({ field }) => (
                        <div className={`input-container ${!!errors.company ? "error" : ""}`}>
                          <p> Company </p>
                          <input
                            className="input"
                            placeholder="Company"
                            {...field}
                            onFocus={() => {
                              setFieldError("company", "");
                            }}
                          />
                          <div className="inputHelper">{errors.company}</div>
                        </div>
                      )}
                    />
                  </div>
                  <div className="input-row">
                    <Field
                      name="phone"
                      render={({ field }) => (
                        <div className={`input-container ${!!errors.phone ? "error" : ""}`}>
                          <p> Your Phone* </p>
                          <InputMask
                            className="input"
                            mask="+99999999999999"
                            maskChar={null}
                            placeholder="Phone"
                            {...field}
                            onFocus={() => {
                              setFieldError("phone", "");
                            }}
                          />
                          <div className="inputHelper">{errors.phone}</div>
                        </div>
                      )}
                    />
                    <Field
                      name="email"
                      render={({ field }) => (
                        <div className={`input-container ${!!errors.email ? "error" : ""}`}>
                          <p> Your E-mail* </p>
                          <input
                            className="input"
                            placeholder="Email"
                            {...field}
                            onFocus={() => {
                              setFieldError("email", "");
                            }}
                          />
                          <div className="inputHelper">{errors.email}</div>
                        </div>
                      )}
                    />
                  </div>
                  <Field
                    name="description"
                    render={({ field }) => (
                      <div className={`input-container fullWidth ${!!errors.description ? "error" : ""}`}>
                        <p> Project description* </p>
                        <TextareaAutosize
                          minRows={5}
                          maxRows={20}
                          className="input"
                          placeholder="Project description"
                          rows="6"
                          {...field}
                          onKeyDown={event => event.key === "Enter" && event.preventDefault()}
                          onChange={event => {
                            if (event.target.value.length > 1000) {
                              setFieldError("description", "Description must be less than 1001 characters");
                              event.preventDefault();
                            } else {
                              setFieldError("description", "");
                              field.onChange(event);
                            }
                          }}
                          onFocus={() => {
                            setFieldError("description", "");
                          }}
                        />
                        <div className="inputHelper">{errors.description}</div>
                      </div>
                    )}
                  />
                  <div className={"buttons-wrap"}>
                    <button type="submit" className="radiusButton white" id="submit-form">
                      {this.state.sending ? "Message sent" : "Submit"}
                    </button>
                    <Field name="file" render={() => this.handleRenderFileInput(errors, values, setFieldValue)} />
                  </div>
                </Form>
              );
            }}
          />
          <div className={"requestaQuote-contacts"}>
            <h3>Contacts:</h3>
            <div className={"requestaQuote-contacts-item"}>
              <img src={"/assets/images/homepage/email_24px_rounded.svg"} alt="mail" />
              <a href="mailto:contact@powercode.co.uk">contact@powercode.co.uk</a>
            </div>
            <div className={"requestaQuote-contacts-item"}>
              <img src={"/assets/images/homepage/location_on_24px_rounded.svg"} alt="location" />
              <p>
                <HashLink to={"/contact#our-locations"} smooth>
                  Our locations
                </HashLink>
              </p>
            </div>
            <div className={"requestaQuote-contacts-item"}>
              <img src={"/assets/images/homepage/call_24px_rounded.svg"} alt="phone" />
              <a href="tel:+442045772044">+442045772044</a>
            </div>
            <div className="requestaQuote-contacts-image">
              <img src={"/assets/images/homepage/Cone.svg"} alt="decorator" className="decorator" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

async function requestForm(body, cb) {
  const formData = new FormData();

  for (let name in body) {
    if (name === "file") {
      formData.append("file", body[name]);
    } else {
      formData.append("acf[" + name + "]", body[name]);
    }
  }

  let headers = {
    accept: "application/json",
    Authorization: "Basic " + btoa("form_api:RVhJ DHae C3dP bLSP pUXa K9OQ"),
  };

  // TODO remove this rule after implementation feature
  /* eslint-disable no-unused-vars */
  try {
    let req = await API.post("/main_form", formData, { headers: headers }).then(response => {
      cb();
    });
  } catch (error) {
    console.error("main_form", error);
  }
}

export default withRouter(RequestForm);
