import React, { Suspense, useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";

import Header from "./components/Header";
import Footer from "./components/Footer";
import FBChatPhone from "./components/FBChatPhone";
import UHeader from "./containers/UHeader/UHeader";
import { AppContext, useAppContext } from "./AppHooks";

import {
  Blog,
  BlogPages,
  CaseStudies,
  Contact,
  EmptyPage,
  Expertise,
  HomePage,
  MessageSent,
  OurTeam,
  PrivacyPolicy,
  ServicePage,
  UCaseStudiesSubPage,
} from "./Routes";

import "./App.scss";
import Loading from "./components/Loader";

const ScrollToTop = ({ history }) => {
  if (typeof window !== "undefined" && typeof document !== "undefined") {
    history.listen((location, action) => {
      if (action === "REPLACE" && location.pathname === "/contact") {
        //silent
      } else {
        document.documentElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }
    });

    const nav = document.querySelector("nav.u_navigation");
    if (nav) {
      nav.classList.remove("page_404");
    }
  }
  return null;
};

function App() {
  useEffect(() => {
    if (typeof window !== "undefined") {
      document.getElementById("root").classList.remove("loading");
    }
  }, []);

  let windowObj;

  if (typeof window !== "undefined") {
    windowObj = window.innerWidth;
  }

  const [deviceWidth, setDeviceWidth] = useState(windowObj);

  const handleResize = () => {
    if (typeof window !== "undefined") {
      setDeviceWidth(window.innerWidth);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleResize);

      return window.addEventListener("resize", handleResize);
    }
  });

  const appStore = useAppContext();

  return (
    <>
      <AppContext.Provider value={appStore}>
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route path={["/privacy-policy", "/blog-article/:slug", "/blog", "/message_sent"]}>
              <Header />
            </Route>
            <Route path={["/"]}>
              <UHeader />
            </Route>
          </Switch>
          <div className="App" data-scroll>
            <div data-scrollable>
              <Route component={ScrollToTop} />
              <Switch>
                <Route path="/" exact>
                  <HomePage deviceWidth={deviceWidth} />
                </Route>
                <Route path="/privacy-policy" component={PrivacyPolicy} />
                <Route path="/expertise/:expertise" render={() => <Expertise deviceWidth={deviceWidth} />} />
                <Route path="/contact" component={Contact} />
                {/* <Route path="/contact-us-new" component={ContactUsNew} /> */}
                <Route path="/our-team" component={OurTeam} />
                <Route path="/services/:slug" component={() => <ServicePage deviceWidth={deviceWidth} />} />
                <Route path="/case-studies/:slug" component={UCaseStudiesSubPage} />
                <Route path="/case-studies" component={CaseStudies} />
                <Route path="/blog/:slug" component={BlogPages} />
                <Route path="/blog" component={Blog} />
                <Route path="/message_sent" component={MessageSent} />
                <Route
                  render={({ staticContext }) => {
                    if (staticContext) {
                      staticContext.statusCode = 404;
                    }
                    return <EmptyPage />;
                  }}
                />
              </Switch>

              <Footer />
              <FBChatPhone deviceWidth={deviceWidth} />
            </div>
          </div>
        </Suspense>
      </AppContext.Provider>
    </>
  );
}

export default withRouter(App);
