import React from "react";
import UIcon from "../UIcon";

/* eslint-disable no-unused-vars */
export default function IconWebSite({ children, className = "", ...restProps }) {
  return (
    <UIcon
      width="20"
      height="16"
      viewBox="0 0 20 16"
      path="M2 0H18C19.1 0 20 0.9 20 2V14C20 15.1 19.1 16 18 16H2C0.9 16 0 15.1 0 14L0.01 2C0.01 0.9 0.9 0 2 0ZM12.5 5H2V8.5H12.5V5ZM2 10.5H12.5V14H3C2.45 14 2 13.55 2 13V10.5ZM14.5 14H17C17.55 14 18 13.55 18 13V5H14.5V14Z"
      {...restProps}
    />
  );
}
