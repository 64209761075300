import { useState, createContext, useEffect } from "react";
import SmoothScroll from "./helpers/scroll";
import breakpoints from "./constants/breakpoints";

export const AppContext = createContext({});

function setContextValue(value, setter) {
  return {
    value,
    get: () => value,
    set: setter,
  };
}

function handleResize() {
  let currentDisplayType = "desktop";
  if (typeof window !== "undefined") {
    if (window.innerWidth <= breakpoints.md) {
      currentDisplayType = "tablet";
    }
    if (window.innerWidth <= breakpoints.lg) {
      currentDisplayType = "tablet";
    }
    if (window.innerWidth <= breakpoints.sm) {
      currentDisplayType = "mobile";
    }
  }
  return currentDisplayType;
}

function handleResizeNew() {
  if (typeof window !== "undefined") {
    const width = window.innerWidth;

    if (width <= breakpoints.sm) {
      return "sm";
    }

    if (width <= breakpoints.md) {
      return "md";
    }

    if (width <= breakpoints.lg) {
      return "lg";
    }

    if (width <= breakpoints.xl) {
      return "xl";
    }
  }

  return "full";
}

export function useAppContext() {
  const [isCookie, setIsCookie] = useState(false);
  useEffect(() => {
    if (typeof window !== "undefined") {
      setIsCookie(!!window.localStorage.getItem("acceptCookies"));
    }
  }, []);

  const [displayType, setDisplayType] = useState(handleResize());
  const [displayTypeNew, setDisplayTypeNew] = useState(handleResizeNew());
  useEffect(() => {
    window.addEventListener("resize", () => {
      const newDisplayType = handleResize();
      setDisplayType(newDisplayType);

      setDisplayTypeNew(handleResizeNew());
    });
    return () => {
      window.addEventListener("resize", () => {
        const newDisplayType = handleResize();
        setDisplayType(handleResize(newDisplayType));

        setDisplayTypeNew(handleResizeNew());
      });
    };
  }, []);

  const store = {
    isCookie: setContextValue(isCookie, value => {
      return setIsCookie(value);
    }),
    displayType: setContextValue(displayType, value => {
      setDisplayType(value);
    }),
    displayTypeNew: setContextValue(displayTypeNew, value => {
      setDisplayTypeNew(value);
    }),
  };
  return store;
}

export function useScroll(enabled) {
  const [scroll, setScroll] = useState(null);
  useEffect(() => {
    let effectScroll = {};
    if (enabled) {
      effectScroll = new SmoothScroll();
      setScroll(effectScroll);
      window.addEventListener("resize", () => {
        effectScroll.refreshWindowSizes();
      });
      window.addEventListener("scroll", () => {
        effectScroll.getPageYScroll();
      });

      const lines = document.querySelector("[data-scroll-lines]");
      effectScroll.interpolationCallbacks.lines = (t, curr) => {
        const margin = Math.pow(t, 2) * (curr / 20);
        lines.style.paddingTop = `${margin}px`;
      };
    }

    return () => {
      if (enabled) {
        setScroll(null);
        window.removeEventListener("resize", () => {
          effectScroll.refreshWindowSizes();
        });
        window.removeEventListener("scroll", () => {
          effectScroll.getPageYScroll();
        });
      }
    };
  }, []);

  return scroll;
}
