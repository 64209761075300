import React from "react";
import { createRoot, hydrateRoot } from "react-dom/client";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { loadableReady } from "@loadable/component";
import "./index.scss";
import App from "./App";
import configureStore from "./store/configureStore";
// import registerServiceWorker from './registerServiceWorker';

const store = configureStore(window.__REDUX_STATE__ || {});

const domNode = document.getElementById("root");

const AppBundle = (
  <ReduxProvider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ReduxProvider>
);

window.onload = () => {
  if (process.env.NODE_ENV === "production") {
    // Wait for all chunks to be loaded when in production
    loadableReady(() => {
      hydrateRoot(domNode, AppBundle);
    });
  } else {
    // For development, create and render to root immediately (assuming you are not using SSR in development)
    const root = createRoot(domNode);
    root.render(AppBundle);
  }
};

// registerServiceWorker();
