import React from "react";
import UIcon from "../UIcon";

/* eslint-disable no-unused-vars */
export default function IconTarget({ children, className = "", ...restProps }) {
  return (
    <UIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      path="M21.7527 10.9091C21.2509 6.36 17.64 2.74909 13.0909 2.24727V1.09091C13.0909 0.490909 12.6 0 12 0C11.4 0 10.9091 0.490909 10.9091 1.09091V2.24727C6.36 2.74909 2.74909 6.36 2.24727 10.9091H1.09091C0.490909 10.9091 0 11.4 0 12C0 12.6 0.490909 13.0909 1.09091 13.0909H2.24727C2.74909 17.64 6.36 21.2509 10.9091 21.7527V22.9091C10.9091 23.5091 11.4 24 12 24C12.6 24 13.0909 23.5091 13.0909 22.9091V21.7527C17.64 21.2509 21.2509 17.64 21.7527 13.0909H22.9091C23.5091 13.0909 24 12.6 24 12C24 11.4 23.5091 10.9091 22.9091 10.9091H21.7527ZM12 7.63636C9.58909 7.63636 7.63636 9.58909 7.63636 12C7.63636 14.4109 9.58909 16.3636 12 16.3636C14.4109 16.3636 16.3636 14.4109 16.3636 12C16.3636 9.58909 14.4109 7.63636 12 7.63636ZM4.36364 12C4.36364 16.2218 7.77818 19.6364 12 19.6364C16.2218 19.6364 19.6364 16.2218 19.6364 12C19.6364 7.77818 16.2218 4.36364 12 4.36364C7.77818 4.36364 4.36364 7.77818 4.36364 12Z"
      {...restProps}
    />
  );
}
