import React from "react";
import UIcon from "../UIcon";

/* eslint-disable no-unused-vars */
export default function IconCross({ children, className = "", ...restProps }) {
  return (
    <UIcon
      width="18"
      height="18"
      viewBox="0 0 18 18"
      path="M0.292893 16.2929C-0.0976311 16.6834 -0.0976311 17.3166 0.292893 17.7071C0.683417 18.0976 1.31658 18.0976 1.70711 17.7071L0.292893 16.2929ZM17.7071 1.70711C18.0976 1.31658 18.0976 0.683417 17.7071 0.292893C17.3166 -0.0976311 16.6834 -0.0976311 16.2929 0.292893L17.7071 1.70711ZM16.2929 17.7071C16.6834 18.0976 17.3166 18.0976 17.7071 17.7071C18.0976 17.3166 18.0976 16.6834 17.7071 16.2929L16.2929 17.7071ZM1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L1.70711 0.292893ZM1.70711 17.7071L17.7071 1.70711L16.2929 0.292893L0.292893 16.2929L1.70711 17.7071ZM17.7071 16.2929L1.70711 0.292893L0.292893 1.70711L16.2929 17.7071L17.7071 16.2929Z"
      fillRule="nonzero"
      {...restProps}
    />
  );
}
