import React, { PureComponent } from "react";
import "./index.scss";
import Img from "../Img/Img";

class ModalDialog extends PureComponent {
  componentDidUpdate(prevProps) {
    if (this.props.isShow !== prevProps.isShow) {
      this.setBodyOverflow(this.props.isShow);
    }
  }

  componentWillUnmount() {
    this.setBodyOverflow(false);
  }

  setBodyOverflow = isShow => {
    if (isShow) {
      document.body.classList.add("modalOpen");
    } else {
      document.body.classList.remove("modalOpen");
    }
  };

  render() {
    const { isShow, onClose, children } = this.props;
    if (!isShow) {
      return null;
    }
    return (
      <div className="modalDialog">
        <div className="modalDialog-backdrop" onClick={onClose} />
        <div className="modalDialog-container">
          <Img src="/assets/images/close-button.svg" className="modalDialog-close" onClick={onClose} alt="close-btn" />
          {children}
        </div>
      </div>
    );
  }
}

export default ModalDialog;
