import { API } from "../Api";

export const OBTAIN_COMPANY_DATA = "OBTAIN_COMPANY_DATA";
export const OBTAIN_COMPANY_ERROR = "OBTAIN_COMPANY_ERROR";
export const SET_COMPANY_SSR = "SET_COMPANY_SSR";

export function setCompanySSR(isSSR) {
  return {
    type: SET_COMPANY_SSR,
    payload: isSSR,
  };
}

function obtainCompanyData(data) {
  return {
    type: OBTAIN_COMPANY_DATA,
    payload: data,
  };
}

function obtainCompanyError(data) {
  return {
    type: OBTAIN_COMPANY_ERROR,
    payload: data,
  };
}

export const getCompanyPageContent = () => async dispatch => {
  try {
    const contentRes = API.get("pages/1796");
    const teamRes = API.get("/team");
    const teamReviewsRes = API.get("/team_reviews");

    const [content, team, teamReviews] = await Promise.all([contentRes, teamRes, teamReviewsRes]);
    return dispatch(
      obtainCompanyData({
        ...(Boolean(content.data.acf) && content.data.acf),
        our_team: team.data.sort((a, b) => (a?.acf?.priority || 1000) - b?.acf?.priority),
        team_reviews: teamReviews?.data,
      }),
    );
  } catch (err) {
    dispatch(obtainCompanyError(["Error"]));
  }
};
