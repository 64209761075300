import {
  SET_CASE_SSR,
  SET_CASE_ITEM_SSR,
  SET_CURRENT_CASE,
  OBTAIN_CASE_DATA,
  OBTAIN_CASE_ERROR,
  OBTAIN_CASE_ITEM_DATA,
  OBTAIN_CASE_ITEM_ERROR,
  OBTAIN_CASE_SIMILAR_DATA,
  OBTAIN_CASE_SIMILAR_ERROR,
  OBTAIN_CASE_CATEGORIES,
} from "../actions";
import { DEFAULT_CATEGORY } from "./blog";

const initialState = {
  isCaseSSR: false,
  isCaseItemSSR: false,
  caseList: [],
  categoriesList: [DEFAULT_CATEGORY],
  caseListTotal: 0,
  caseListError: [],
  caseItem: {},
  caseItemError: [],
  caseSimilar: [],
  caseSimilarError: [],
  currentCase: null,
};

export default function caseReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_CASE:
      return {
        ...state,
        currentCase: action.payload,
      };
    case SET_CASE_SSR:
      return {
        ...state,
        isCaseSSR: action.payload,
      };
    case SET_CASE_ITEM_SSR:
      return {
        ...state,
        isCaseItemSSR: action.payload,
      };
    case OBTAIN_CASE_DATA:
      return {
        ...state,
        caseList: action.payload.data,
        caseListTotal: action.payload.total,
        caseListError: [],
      };
    case OBTAIN_CASE_CATEGORIES:
      return {
        ...state,
        categoriesList: [...state.categoriesList, ...action.payload],
        caseListError: [],
      };
    case OBTAIN_CASE_ERROR:
      return {
        ...state,
        caseList: [],
        caseListError: action.payload,
      };
    case OBTAIN_CASE_ITEM_DATA:
      return {
        ...state,
        caseItem: action.payload,
        caseItemError: [],
      };
    case OBTAIN_CASE_ITEM_ERROR:
      return {
        ...state,
        caseItem: [],
        caseItemError: action.payload,
      };
    case OBTAIN_CASE_SIMILAR_DATA:
      return {
        ...state,
        caseSimilar: action.payload,
        caseSimilarError: [],
      };
    case OBTAIN_CASE_SIMILAR_ERROR:
      return {
        ...state,
        caseSimilar: [],
        caseSimilarError: action.payload,
      };
    default:
      return state;
  }
}
