import React from "react";
import "./UButton.scss";

export default function UButton({ as = "button", className = "", primary, secondary, ...restProps }) {
  let variantClasses = "";
  if (primary) {
    variantClasses += " u_button--primary";
  } else if (secondary) {
    variantClasses += " u_button--secondary";
  } else {
    variantClasses += " u_button--primary";
  }

  const Component = as;
  return <Component className={`u_button${variantClasses} ${className}`} {...restProps} />;
}
