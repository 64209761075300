import { combineReducers } from "redux";
import blogReducer from "./blog";
import caseReducer from "./case-studies";
import companyReducer from "./company";
import homeReducer from "./home";
import servicesReducer from "./services";
import expertiseReducer from "./expertise";

export const reducers = combineReducers({
  home: homeReducer,
  company: companyReducer,
  blog: blogReducer,
  caseStudies: caseReducer,
  services: servicesReducer,
  expertise: expertiseReducer,
});
